import { useUser } from '@supabase/auth-helpers-react'
import LoginForm from 'components/forms/LoginForm'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import { navigate, PageProps } from 'gatsby'
import queryString from 'query-string'
import React from 'react'

export default function LoginPage({ location }: PageProps) {
	const user = useUser()

	const params = queryString.parse(location.search)

	const id = params?.id as string
	const hash = params?.hash as string

	if (user) {
		navigate('/core-adoption')
		return null
	} else {
		return (
			<Layout pageName="Login" showHeader={false}>
				<Section className="h-screen">
					<div className="absolute top-0 left-0 z-0 h-full w-full bg-cover bg-no-repeat opacity-50" style={{ backgroundImage: 'url(/images/arc-core/books-bg.jpg)' }}>
						<div className="white-transparent-gradient-strong h-full w-full"></div>
					</div>
					<Row center className="relative z-10 pt-10 sm:pt-16">
						<Col width="w-full sm:w-1/2">
							<h1>
								<img src="/images/logos/arc-core-logo-halo.png" alt="arc core logo" />
							</h1>
						</Col>
					</Row>
					<Row center className="relative z-10 pb-32">
						<LoginForm id={id} hash={hash} />
					</Row>
				</Section>
			</Layout>
		)
	}
}
