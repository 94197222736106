import React from 'react'
import { Router } from '@reach/router'
import Route from 'components/global/Route'
import { PageProps } from 'gatsby'
import LoginPage from 'components/core-adoption/login'
import queryString from 'query-string'
import { supabase } from 'clients/supabase'

export default function LoginRoute({ location }: PageProps) {
	const params = queryString.parse(location.search)

	if (params.logoutCurrent === 'true') {
		supabase.auth.signOut()
	}

	return (
		<Router basepath="/login">
			<Route path="/" location={location} component={LoginPage} />
		</Router>
	)
}
