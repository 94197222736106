import { Col } from 'components/grid'
// TODO: Remove client-side crypto package and move it to api
import CryptoJS from 'crypto-js'
import React, { useState } from 'react'
import { wait } from 'utils/wait'
import { Label, TextInput } from '../global/FormInputs'
import { Button } from '../global/UI'
import { supabase } from 'clients/supabase'

const GATSBY_HASH: string = process.env.GATSBY_HASH as string

interface Props {
	id: string | null
	hash: string | null
}

const LoginForm = ({ id, hash }: Props) => {
	const [username, setUsername] = useState(id ? id : '')
	const [password, setPassword] = useState(
		hash ? CryptoJS.AES.decrypt(CryptoJS.enc.Base64.parse(hash).toString(CryptoJS.enc.Utf8), GATSBY_HASH).toString(CryptoJS.enc.Utf8).replace(/['"]+/g, '') : ''
	)

	const [errorMsg, setErrorMsg] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleLogin = async (event: React.FormEvent<HTMLInputElement>) => {
		try {
			event.preventDefault()
			setLoading(true)
			await wait(800)
			const { error } = await supabase.auth.signInWithPassword({ email: `${username}@coreadoption.com`, password })
			if (error) throw new Error(error.message)
		} catch (error: any) {
			setErrorMsg(error.message)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Col width="w-full md:w-5/12">
			{/* @ts-ignore */}
			<form onSubmit={handleLogin}>
				<div className="my-5">
					<Label name="username" htmlFor="Username" />
					<TextInput
						id="username"
						type="text"
						name="username"
						value={username}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
					/>
				</div>
				<div className="my-5">
					<Label name="password" htmlFor="Password" />
					<div className="relative w-full">
						<button
							tabIndex={-1}
							className="absolute text-slate-800 opacity-25 focus:outline-none"
							style={{ top: 14, right: 10 }}
							type="button"
							onClick={() => setShowPassword(!showPassword)}
						>
							{!showPassword ? (
								<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
									<path
										fillRule="evenodd"
										d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
										clipRule="evenodd"
									/>
									<path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
								</svg>
							) : (
								<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
									<path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
									<path
										fillRule="evenodd"
										d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
										clipRule="evenodd"
									/>
								</svg>
							)}
						</button>
						<input
							id="password"
							type={showPassword ? 'text' : 'password'}
							name="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
							className="rounded-ms w-full border-gray-200 px-4 py-3 hover:shadow-inner focus:border-gray-500 focus:shadow-inner focus:outline-none"
						/>
					</div>
				</div>
				{errorMsg && (
					<div className="mb-4 w-full bg-red-100 p-2">
						<p className="mb-0 text-red-500">{errorMsg}</p>
					</div>
				)}
				<Button loading={loading} size="lg" type="submit">
					Log In
				</Button>
			</form>
		</Col>
	)
}

export default LoginForm
