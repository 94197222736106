import React from 'react'

interface Props {
	component: any
	[x: string]: any
}

const Route = ({ component: Component, ...rest }: Props) => {
	return <Component {...rest} />
}

export default Route
